import React from 'react';
import Layout from '../components/layout/Layout';

const NotFound = () => (
  <div className="absolute inset-0 flex items-center justify-center">
  <div class="bg-sigarPurple shadow-2xl rounded-lg text-center p-5 m-20">
    <h2 class="text-6xl text-white">
      404
    </h2>
    <h2 class="text-3xl text-white">
      The page you are looking for was not found
    </h2>
    <div class="mt-8 flex justify-center">
      <div class="inline-flex rounded-md bg-sigarTeal shadow">
        <a href="/" class="text-white text-xl font-bold py-2 px-6">
          Go to home page
        </a>
      </div>
    </div>
  </div>
</div>
);

export default NotFound;
